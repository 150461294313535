import React from 'react';
import './logo.scss';

const Logo = props => (
  <svg viewBox="0 0 453.6 151.2" {...props}>
    <g fill="#FFF">
      <path d="M100 85.8v.9c0 15-13.4 27.2-29.3 27.2-7.9 0-15.1-3.1-20.4-8-5.3 4.9-12.7 8-20.7 8C13.4 113.8 0 101.2 0 85.8v-.6-36.7c0-4.9 4.1-8.9 9-8.9s9 4 9 8.9V85.8C18 91.4 23.1 96 29.5 96S41 91.4 41 85.8v-.6-36.7c0-4.9 4.1-8.9 9-8.9s9 4 9 8.9V85.8C59 91.4 64.1 96 70.5 96S82 91.4 82 85.8V48.2c0-4.9 4.1-8.9 9-8.9s9 4 9 8.9v37.6zM179 47.3v56.8c0 4.6-4 8.5-8.9 8.5s-8.1-3.9-8.1-8.5v-1.8c-6 5.5-13.7 8.8-22.6 8.8-19.9 0-35.9-16-35.9-35.8s16-35.9 35.9-35.9c8.9 0 16.7 3.4 22.7 8.6v-.7c0-4.6 3.2-8.5 8.1-8.5 4.8.1 8.8 3.9 8.8 8.5zm-21 28.1c0-10.2-8.5-18.6-18.6-18.6-10.2 0-18.7 8.3-18.7 18.6s8.5 18.4 18.7 18.4c10.1 0 18.6-8.2 18.6-18.4zM194.6 26c-5.2 0-9.6-4.2-9.6-9.1v-.7c0-5.2 4.5-9.2 9.6-9.2 4.9 0 9.4 4 9.4 9.2v.7c0 4.9-4.5 9.1-9.4 9.1zm0 87.1c-5.2 0-9.6-4.2-9.6-9.2V46.6c0-5 4.5-9.1 9.6-9.1 4.9 0 9.4 4 9.4 9.1v57.3c0 5-4.5 9.2-9.4 9.2zM253.6 103.7c0 5-4 8.9-8.9 8.9-1.3 0-2.5 0-3.9-.1-4.9-.7-9.5-2.8-12.9-6.7-3-3.4-4.9-8.8-4.9-13.4V54.8h-6.7c-4.9 0-8.9-4.1-8.9-9s4-9 8.9-9h6.7V8.9c0-4.9 3.6-8.9 8.5-8.9s8.5 4 8.5 8.9v27.9h5.4c4.9 0 8.9 4.1 8.9 9s-4 9-8.9 9H240v35.6c1 1.2.2 3 1.3 3.9.7.7 2.2.6 3.2.6 4.9-.1 9.1 4 9.1 8.8zM316.4 113.5c-19.3 0-35.4-16.8-35.4-37.3V47.9c0-4.9 3.9-8.9 8.5-8.9 4.8 0 9.5 4 9.5 8.9v28.3c0 10.7 8.8 19.3 17.4 19.3 10.1 0 17.6-8.6 17.6-19.3V49.1c0-5 5.4-8.9 9.4-8.9 4.6 0 8.6 3.9 8.6 8.9v27.2c0 20.5-16.1 37.2-35.6 37.2zM431.7 76.1c0 20.8-17 37.6-37.6 37.6-7.7 0-15-2.4-21-6.2v34.7c0 5-4 9.1-8.9 9.1-5 0-9.1-4-9.1-9.1V47.8c0-4.9 4.1-8.9 9.2-8.9 4 0 7.5 2.7 8.7 6.2 5.9-4.2 13.4-6.5 21.3-6.5 20.4 0 37.4 16.8 37.4 37.5zm-18 0c0-10.8-8.9-19.6-19.6-19.6-10.8 0-19.6 8.8-19.6 19.6s8.8 19.6 19.6 19.6c10.7 0 19.6-8.7 19.6-19.6zM443.9 84.3c-4.9 0-8.9-4-8.9-8.9V9.5c0-5 4-9.1 8.9-9.1 5 0 9.1 4 9.1 9.1v65.9c0 4.9-4 8.9-9.1 8.9zm.7 28.5h-1c-4.9 0-8.9-4-8.9-9.1 0-4.9 4-8.9 8.9-8.9h1c5 0 9.1 4 9.1 8.9-.1 5.1-4.1 9.1-9.1 9.1z" />
    </g>
  </svg>
);

export default Logo;